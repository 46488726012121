import loadable from '@loadable/component'
import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import config from '../../config'
import { HTMLContent } from '../components/Content'
import GalleryTemplate from '../components/GalleryTemplate'
import SE0 from '../components/SEO'
import Layout from '../components/Layout'
import PortfolioRoll2 from '../components/PortfolioRoll2'
import ReactStars from "react-rating-stars-component"
import { DatabaseRef } from '../firebase'
import Helmet from 'react-helmet'
import ReactDOM from 'react-dom'
import { TrimStr } from '../components/Trim'
import Social from '../components/Social'

const Share = loadable(() => import('../components/Share'))
const ModalCall = loadable(() => import('../components/ModalCall'))
const NewsCall = loadable(() => import('../components/NewsCall'))

const GalleryPage = (props) => {
  const { data: { markdownRemark: { html, id, fields: { slug }, frontmatter: { title, meta_title, meta_description, cover, date, tags, category, keywords } } },pageContext } = props

  for (let i=1;i<keywords.length;i++){
    if (keywords[i].charAt(0)!==' '){
    keywords[i] = ' ' + keywords[i];
    }
    //console.log(keywords[i]);
  }
  //console.log(String(keywords));

  let creativeJSONLD =
  {
				"@context": "https://schema.org/",
				"@type": "CreativeWorkSeries",
				name: title,
				"aggregateRating": {
					"@type": "AggregateRating",
					ratingValue: "0",
					"bestRating": "5",
					ratingCount: "0"
				}
			}

  var trimmedtitle = TrimStr(title);

  let BrewstrRef = DatabaseRef.child('ratings/portfolio/'+trimmedtitle);

  function readRatings(){
    var element;
    BrewstrRef.once("value").then(function(snapshot){
        var loginInfo=snapshot.val();
          //console.log(loginInfo);

        if((loginInfo !== null)&&(loginInfo !== undefined)){
          const entries = Object.entries(loginInfo);
          //console.log(entries.length);

          document.getElementById('numof').innerText = 'ratings: ' + entries.length;

          creativeJSONLD.aggregateRating.ratingCount = String(entries.length);
          console.log(creativeJSONLD.aggregateRating.ratingCount)

          //let ips = [];
          let rats = [];

          for (let i=0;i<entries.length;i++){

            let propertyValues = Object.values(entries[i]);

            //console.log(propertyValues[1].ip);
            //ips.push(propertyValues[1].ip);
            rats.push(Number(propertyValues[1].rating));
          }

          //console.log(ips);
          //console.log(rats);
          let total = 0;
          for (let i=0;i<rats.length;i++){
            total += rats[i];
          }
          let avg = total/rats.length;
          //console.log(avg);
          document.getElementById('avgrat').innerText = 'average rating: ' + avg.toFixed(1);
          creativeJSONLD.aggregateRating.ratingValue = String(avg.toFixed(1));
          console.log(creativeJSONLD.aggregateRating.ratingValue);

           element = (<Helmet>
            {/* Schema.org tags */}
            <script type='application/ld+json'>
            {JSON.stringify(creativeJSONLD)}
            </script>
          </Helmet>);
          ReactDOM.render(

              element,
              document.getElementById('json')

          );

        } else {
          document.getElementById('numof').innerText = 'ratings: 0';
          document.getElementById('avgrat').innerText = 'average rating: 0';

          creativeJSONLD.aggregateRating.ratingValue = '0';
          creativeJSONLD.aggregateRating.ratingCount = '0';


        }

      });

    }

  function json(url) {
    return fetch(url).then(res => res.json());
  }

  const testFire = (newRating) => {

    json('https://api.ipify.org?format=json').then(data => {
      BrewstrRef.once("value").then(function(snapshot){
        var loginInfo=snapshot.val();
        if ((loginInfo !== null) && (loginInfo !== undefined)) {
          const entries = Object.entries(loginInfo);

          let ips = [];
          for (let i=0; i<entries.length; i++) {
            let propertyValues = Object.values(entries[i]);
            ips.push(propertyValues[1].ip);
          }

          if (ips.includes(data.ip)) {
            document.getElementById('rated').style.display = 'block';
            document.getElementById('rated').innerText = 'It seems that you have already evaluated the article.';
            setTimeout(function() {
              if(document.getElementById('rated') !== null) {
                document.getElementById('rated').style.display = 'none';
              }
            }, 1500)
          } else {
            BrewstrRef.push().set({rating:newRating, ip: data.ip});
            readRatings();
          }
        } else {
          BrewstrRef.push().set({rating:newRating, ip: data.ip});
          readRatings();
        }
      })
    })
  }

  readRatings();

  const {next, prev} = pageContext


  return (
    <Layout>
      <Helmet>
      <script type='application/ld+json'>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Portfolio",
          "item": "https://artopen.co/portfolio/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": title,
        }]
      })}
      </script>
      <link rel="canonical" href={'https://artopen.co'+slug} />
       </Helmet>
      <div id="json"></div>
      <section>
        <SE0
          title={title}
          meta_title={meta_title}
          meta_desc={meta_description}
          keywords={keywords}
          cover={cover.publicURL}
          slug={slug}
          date={date}
          siteTitleAlt={config.siteTitleAlt}
          userName={config.userName}
          siteTitle={config.siteTitle}
          siteUrl={config.siteUrl}
          siteFBAppID={config.siteFBAppID}
          userTwitter={config.userTwitter}
          pathPrefix={config.pathPrefix}
        />
        <div style={{marginLeft:'0px',padding:'0px'}}>

        <Social />


        <nav className="breadcrumbs-nav" style={{top:'30px',left:'12%'}}>
        <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<Link className="inline-link" to="/portfolio/">Portfolio</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>{title}</b></span>
        </nav>
          <GalleryTemplate
            content={html}
            contentComponent={HTMLContent}
            cover={cover.publicURL}
            meta_title={meta_title}
            meta_desc={meta_description}
            tags={tags}
            title={title}
            category={category}
            date={date}
          />
          <div>
            <div className='column is-10 is-offset-1'>
            <br />
              <p className='subtitle' style={{marginLeft:'5%',maxWidth:'90%', textAlign:'center'}}> <b>Evaluate our work:</b> </p>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <ReactStars
                 count={5}
                 onChange={testFire}
                 size={34}
                 activeColor="#23C495"
               />
              </div>
              <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
              <sub id='numof'></sub>&nbsp;&nbsp;&nbsp;
              <sub id='avgrat'></sub>
              </div>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'45px'}}>
              <p style={{display:'none'}} id='rated'></p>
              </div>
              <Share
                title={title}
                slug={slug}
                excerpt={meta_description}
                siteUrl={config.siteUrl}
                pathPrefix={config.pathPrefix}
              />
              <hr />
              <br />
              {/*<div className="columns">
              {prev && prev.frontmatter.templateKey == "gallery-page"  ? <Link className="column button-green" style={{textAlign:'center'}} to={prev.fields.slug} ><span style={{color:'#333',fontSize:'13px'}}>Następny wpis:</span><br /><br /> &#8678; {prev.frontmatter.title} <br /><br /> <span style={{color:'#333',fontSize:'12px'}}>Reading time: {prev.timeToRead} min</span> </Link>  : ' '}
              &nbsp;  &nbsp;  &nbsp;
              {next && next.frontmatter.templateKey == "gallery-page"  ?  <Link className="column button-green" style={{textAlign:'center'}} to={next.fields.slug}><span style={{color:'#333',fontSize:'13px'}}>Poprzedni wpis:</span><br /><br />{next.frontmatter.title} &#8680;<br /><br /> <span style={{color:'#333',fontSize:'12px'}}>Reading time: {next.timeToRead} min</span></Link> : ' '}
              </div>*/}
              <br />
              <br />
            </div>
          </div>
          <br />
          <div className='bottomUi' style={{textAlign:'center',marginBottom:'30px'}}>
          <Link className='button-green' style={{fontSize:'15px'}} to='/offer/'> Discover our offer&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className='button-green' style={{fontSize:'15px'}} to='/blog/'> Read the blog&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className='button-green' style={{fontSize:'15px'}} to='/contact/'> Contact us&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
          </div>
          <br />
          <br />



        </div>
      </section>
      <p style={{fontSize:'14px',marginLeft:'20%',marginRight:'20%',marginBottom:'50px'}}>Answer the questions and get free service pricing. In our offer you will find many services, such as <Link className="inline-link" to='/offer/graphic-design/'>graphic design</Link>, personalized <Link className="inline-link" to='/offer/book-calendars/'>book calendars</Link>, <Link className="inline-link" to='/offer/company-calendars/'>company calendars</Link>, <Link className="inline-link" to='/offer/ecology/'>ecological calendars</Link> <Link className="inline-link" to='/offer/gadgets/'>advertising gadgets</Link>, <Link className="inline-link" to='/offer/printouts/'>printouts</Link>, <Link className="inline-link" to='/offer/filming/'>advertising movies</Link> and <Link className="inline-link" to='/offer/photography/'>advertising photos</Link> as well as <Link className="inline-link" to='/offer/web-pages/'>website programming, including e-commerce, applications, </Link> or <Link className="inline-link" to='/offer/vr/'>AR and VR systems.</Link></p>
        <ModalCall />
      <br /><br />
      <p className='is-title' style={{fontSize:'30px',margin:'50px'}}> Other <b style={{color:'#00d1b2'}}>projects</b>: </p>
      <div style={{textAlign:'center',margin:'50px'}}>
        <PortfolioRoll2 />
        <br />
        <br />
        <Link className='button-green' style={{fontSize:'18px'}} to='/portfolio/'> Return to portfolio &nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        <br />
      </div>
      <br />

      <br />
      <br />
      <br />
      <NewsCall />
    </Layout>
  )
}

GalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default GalleryPage

export const pageQuery = graphql`
  query GalleryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
            slug
          }
      frontmatter {
        date(formatString: "DD. MM. YYYY")
        title
        cover {
            publicURL
        }
        meta_title
        meta_description
        tags
        category
        keywords
      }
    }
  }
`
