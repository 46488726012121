import React from 'react'
import Content from '../Content'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'
import ProgressiveImageContainer from '../ProgressiveImageContainer'

const GalleryTemplate = (props) => {
  const { content, contentComponent, cover, tags, title, category, date } = props
  const PostContent = contentComponent || Content
  return (
    <>

    <a href="#content">
    <div className="imgcont"
    style={{backgroundImage:'url('+cover+')',backgroundSize:'cover',backgroundAttachment:'fixed',marginTop:'50px',paddingBottom:'2%',paddingTop:'30%',marginLeft:'10%',marginRight:'10%'}}
    >
      <div className='portitle'>
      <h1 className='title is-size-2 has-text-weight-bold is-bold-light stays-dark'>
        {title}
      </h1>

      <h2 className="subtitle stays-green" style={{fontSize:'18px'}} >&bull; {category}</h2>
      <small className="categ stays-white" style={{marginLeft:'0px'}}>{date}</small>
      </div>
      </div>
      </a>
      <br />
      <div style={{textAlign:'center',marginTop:'20px',marginBottom:'-20px'}}>

      <a href="#content">  <img src="/img/angle-down.svg" width='20px'
        style={{filter:'brightness(1) invert(0)'}} alt="arrow"
        /> </a>

      </div>
      <div id="content" style={{paddingTop:'50px',paddingBottom:'50px'}}>

        <br />
        <br />
        <PostContent content={content} />

        <div style={{marginTop: `50px`,maxWidth:'90%',paddingLeft:'10%'}}>
          <p className='subtitle'><b>Tags:</b></p>
          <br />
          <ul className='taglist columns' style={{marginLeft:'10px',display: 'flex', flexWrap: 'wrap',lineHeight: '3.5'}}>
            {(tags && tags.length)
              ? tags.map(tag => (
                <li className='tags1' key={tag + `tag`}>
                  <Link className="button-green" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link> &nbsp; &nbsp;
                </li>
              ))
              : null}
          </ul>
        </div>

      </div>
      </>
  )
}

export default GalleryTemplate
